import React from 'react';
import Subtitle from './Subtitle';
import Divider from './Divider';
import Form from './Form';
import Notification from './Notification';
import Dynamic from './Dynamic';
import Loader from './Loader';
import FormField from './FormField';
import TextInput from './TextInput';
import EmailAddressants from './EmailAddressants'
import IntroductionTextInput from './IntroductionTextInput'

class SendReminders extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      addressants: [""],
      availableAddressants: [],
      subject: "",
      message: "",
      loading: true,
      error: false
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAddressantsChange = this.handleAddressantsChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this)
    this.requests = [];
  }

  componentDidMount() {
    const component = this;
    const callback = function(response) {
      if (response.error) {
        component.setState({
          error: response.data,
          loading: false,
        });
      }
      else {
        const availableAddressants = [{
          name: response.data.contact_name,
          email: response.data.email,
          checked: false
        }];
        for(let i=0; i < response.data.additional_contacts.length; i++) {
          availableAddressants.push({
            name: response.data.additional_contacts[i].name,
            email: response.data.additional_contacts[i].email,
            checked: false
          })
        }
        component.setState({
          loading: false,
          error: false,
          availableAddressants,
        });
      }
    }
    this.request = this.props.server.request(callback, 'get', 'quota/customer?id=' + this.props.customer);
  }

  componentWillUnmount() {
    if (this.requests) for (let i=0; i < this.request; i++) this.props.server.abortRequest(this.requests[i]);
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      [name] : value
    });
    event.preventDefault();
  }

  handleMessageChange(value) {
    this.setState({
      message: value
    })
  }

  handleAddressantsChange(value) {
    this.setState({
      addressants: value
    })
  }

  render() {
    const {server, onSucces, onCancel} = this.props;
    const {addressants, availableAddressants, subject, message, loading, error} = this.state;
    const url = "tracka/reminder";
    const checkedAddressants = addressants.map((email, index) => {
      // This is the primary addressant
      if  (index === 0 || !Boolean(email)) { return null }
      return {email, name: ""}
    }).filter(Boolean)
    const invoices = [];
    for (let i=0; i < this.props.invoices.length; i++) {
      invoices.push(this.props.invoices[i].id);
    }

    const data = {
      invoice_id: invoices,
      subject: subject,
      message: message,
      addressant: !Boolean(addressants[0]) ? [] : [{
        email: addressants[0],
        name: ""
      }],
      additional_addressants: checkedAddressants
    }

    console.log(data)

    const multipleInvoices = this.props.invoices.length > 1;
    let title = "Send reminder" + (multipleInvoices? "s" : "") + " for invoice" + (multipleInvoices ? "s " : " ");
    for (let i=0; i < this.props.invoices.length; i++) {
      if (i <= this.props.invoices.length - 1 && i > 0) {
        if (i === this.props.invoices.length - 1) title += " and ";
        else title += ", ";
      }
      title += " #" + this.props.invoices[i].number;
    }
    return(
      <Form
        method="post"
        server={server}
        url={url}
        data={data}
        onSucces={onSucces}
        onCancel={onCancel}>
        <Subtitle className="less-padding" label={title} />
        <Notification danger>{error}</Notification>
        <Dynamic visible={loading}>
        {this.state.loading &&
          <Loader />
        }
        </Dynamic>
        <Dynamic visible={!loading && !error}>
          <EmailAddressants
            value={addressants}
            availableAddressants={availableAddressants}
            onChange={this.handleAddressantsChange}
          />
          <FormField className="has-padding-top">
            <TextInput
              name="subject"
              small
              expanded
              placeholder="Subject"
              value={subject}
              onChange={this.handleInputChange} />
          </FormField>
          <FormField>
            <IntroductionTextInput
              name="message"
              placeholder="Message"
              html={message}
              onChange={this.handleMessageChange}
            />
          </FormField>
        </Dynamic>
        <Divider />
      </Form>
    )
  }
}

export default SendReminders;
