import React from 'react';
import Subtitle from './Subtitle';
import TextInput from './TextInput';
import Label from './Label';
import FormField from './FormField';
import Divider from './Divider';
import Form from './Form';
import Notification from './Notification';
import Dynamic from './Dynamic';
import Switch from './Switch';
import Loader from './Loader';
import FormControl from './FormControl';
import EmailAddressants from './EmailAddressants'
import IntroductionTextInput from './IntroductionTextInput'

class MailInvoiceForm extends React.Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth()+1;
    const yyyy = today.getFullYear();
    this.state = {
      purchaseOrder: "",
      paymentTerm: 7,
      jobNumber: "",
      sendMail: true,
      addressants: [""],
      additionalAddressants: [],
      primaryAdressant: {},
      date: yyyy + '-' + mm + '-' + dd,
      subject: "",
      message: "",
      loading: true,
      error: false
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAddressantsChange = this.handleAddressantsChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this)
  }

  componentDidMount() {
    const component = this;
    const callback = function(response) {
      if (response.error) {
        component.setState({
          error: response.data,
          loading: false,
        });
      }
      else {
        const email = {
          subject: "TMOC - Invoice " + component.props.number + " - " + component.props.title,
          message: {
            body: "<p>Please find invoice " + component.props.number + " attached for your convenience which covers our work on " + component.props.title + ".</p><p>As always, it was our pleasure working with you and we look forward to working with you again in the future.</p><p>This invoice has also been added to the project tracker sheet for future reference.</p><p>Link:</p>",
            greeting: "<p>On behalf of Three Thirds,</p><p>" + component.props.server.apiUser().name + "</p>"
          }
        };
        const additionalAddressants = [];
        const contacts = response.data;
        for (let i=0; i < contacts.length; i++) {
          const contact = contacts[i];
          if (contact.primary) {
            component.setState({
              subject: email.subject,
              message: "Dear " + contact.name + "\n\n" + email.message.body + email.message.greeting,
              primaryAdressant: {
                name: contact.name,
                email: contact.email
              },
              addressants: [contact.email],
            });
          }
          else additionalAddressants.push({
            name: contact.name,
            email: contact.email,
            checked: false,
          });
        }
        component.setState({
          loading: false,
          error: false,
          additionalAddressants: additionalAddressants
        });
      }
    }
    this.request = this.props.server.request(callback, 'get', 'quota/iteration?action=contacts&id=' + component.props.iterationId);
  }

  handleAddressantsChange(value) {
    this.setState({
      addressants: value
    })
  }

  handleMessageChange(value) {
    this.setState({
      message: value
    })
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = (target.type === "checkbox" ? target.checked : target.value);
    this.setState({
      [name] : value
    });
    if (!target.type === "checkbox") event.preventDefault();
  }

  render() {
    const {server, id, onSucces, onCancel} = this.props;
    const {sendMail, date, jobNumber, purchaseOrder, paymentTerm, additionalAddressants, addressants, subject, message, loading, error} = this.state;
    const url = "quota/invoice?action=publish&id=" + id;
    const checkedAddressants = addressants.map((email, index) => {
      // This is the primary addressant
      if  (index === 0 || !Boolean(email)) { return null }
      return {email, name: ""}
    }).filter(Boolean)
    const data = {
      date: date,
      job_number: jobNumber,
      purchase_order: purchaseOrder,
      payment_term: paymentTerm,
      send_mail: sendMail,
      subject: subject,
      message: message,
      additional_addressants: checkedAddressants
    }

    return(
      <Form
        method="post"
        server={server}
        url={url}
        data={data}
        onSucces={onSucces}
        onCancel={onCancel}>
        <Subtitle className="less-padding" label={"Publish invoice " + this.props.index + " of " + this.props.total +  " (" + this.props.percentage + "%)"} />
        <Notification danger>{error}</Notification>
        <Dynamic visible={loading}>
        {this.state.loading &&
          <Loader />
        }
        </Dynamic>
        <Dynamic visible={!loading && !error}>
          <Divider />
          <FormField variant="grouped">
            <FormControl>
            <Label text="Date" />
            <TextInput
              name="date"
              placeholder="YYYY-MM-DD"
              value={this.state.date}
              onChange={this.handleInputChange} />
            </FormControl>
            <FormControl>
            <Label text="Payment term" />
            <TextInput
              name="paymentTerm"
              placeholder="Payment term"
              value={paymentTerm}
              onChange={this.handleInputChange} />
              </FormControl>
          </FormField>
          <FormField variant="grouped">
            <FormControl>
              <Label text="Job Number" />
              <TextInput
                name="jobNumber"
                placeholder="Job Number"
                value={jobNumber}
                onChange={this.handleInputChange} />
            </FormControl>
            <FormControl>
              <Label text="Purchase Order" />
              <TextInput
                name="purchaseOrder"
                placeholder="Purchase order"
                value={purchaseOrder}
                onChange={this.handleInputChange} />
            </FormControl>
          </FormField>
          <FormField>
            <FormControl>
              <Label text="Send email" />
              <Switch
                name="sendMail"
                checked={sendMail}
                onChange={this.handleInputChange} />
            </FormControl>
          </FormField>
          <EmailAddressants
            onChange={this.handleAddressantsChange}
            disabled={!sendMail}
            value={addressants}
            availableAddressants={additionalAddressants}
            firstReadOnly
          />
          <FormField className="has-padding-top">
            <TextInput
              name="subject"
              small
              expanded
              disabled={!sendMail}
              placeholder="Subject"
              value={subject}
              onChange={this.handleInputChange} />
          </FormField>
          {!this.state.loading && !this.state.error && (
            <FormField>
              <IntroductionTextInput
                disabled={!sendMail}
                html={message}
                placeholder="Message"
                name="message"
                onChange={this.handleMessageChange}
              />
            </FormField>
          )}
        </Dynamic>
        <Divider />
      </Form>
    )
  }
}

export default MailInvoiceForm;
