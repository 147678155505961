import React from 'react';
import Subtitle from './Subtitle';
import Label from './Label';
import Divider from './Divider';
import Form from './Form';
import Switch from './Switch';
import Loader from './Loader';
import Dynamic from './Dynamic';
import Notification from './Notification';
import FormField from './FormField';
import TextInput from './TextInput';
import IntroductionTextInput from './IntroductionTextInput';
import EmailAddressants from './EmailAddressants'
import FormControl from './FormControl'

class PublishForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMail: true,
      addressants: [""],
      additionalAddressants: [],
      primaryAdressant: {},
      subject: "",
      message: "",
      loading: true,
      error: false
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAddressantsChange = this.handleAddressantsChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this)
  }

  componentDidMount() {
    this.setState({ subject: this.props.subject, message: this.props.message });
    const component = this;
    const callback = function(response) {
      if (response.error) {
        console.log()
        component.setState({
          error: response.data,
          loading: false,
        });
      }
      else {
        const additionalAddressants = [];
        const contacts = response.data;
        for (let i=0; i < contacts.length; i++) {
          const contact = contacts[i];
          if (contact.primary) {
            component.setState({
              primaryAdressant: {
                name: contact.name,
                email: contact.email
              },
              addressants: [contact.email]
            });
          }
          else additionalAddressants.push({
            name: contact.name,
            email: contact.email,
            checked: false,
          });
        }
        component.setState({
          loading: false,
          error: false,
          additionalAddressants: additionalAddressants
        });
      }
    }
    this.request = this.props.server.request(callback, 'get', 'quota/iteration?action=contacts&id=' + component.props.iterationId);
  }

  handleMessageChange(value) {
    this.setState({
      message: value
    })
  }

  handleAddressantsChange(value) {
    this.setState({
      addressants: value
    })
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = (target.type === "checkbox" ? target.checked : target.value);
    this.setState({
      [name] : value
    });
    if (!target.type === "checkbox") event.preventDefault();
  }

  render() {
    const {server, id, iterationId, onSucces, onCancel} = this.props;
    const {sendMail, additionalAddressants, addressants, subject, message, loading, error} = this.state;
    const url = "quota/quote?action=publish&id=" + id + "&iteration_id=" + iterationId;
    const checkedAddressants = addressants.map((email, index) => {
      // This is the primary addressant
      if  (index === 0 || !Boolean(email)) { return null }
      return {email, name: ""}
    }).filter(Boolean)
    const data = {
      send_mail: sendMail,
      subject: subject,
      message: message,
      additional_addressants: checkedAddressants
    }
    return(
      <Form
        method="post"
        server={server}
        url={url}
        data={data}
        onSucces={onSucces}
        onCancel={onCancel}>
        <Subtitle label="Publish quote" />
        <Notification danger>{error}</Notification>
        <Dynamic visible={loading}>
          <Loader />
        </Dynamic>
        <Dynamic visible={!loading && !error}>
          <Divider />
          <FormField>
            <Label text="Send email" />
            <Switch
              name="sendMail"
              checked={sendMail}
              onChange={this.handleInputChange} />
          </FormField>
          <EmailAddressants
            value={addressants}
            disabled={!sendMail}
            onChange={this.handleAddressantsChange}
            availableAddressants={additionalAddressants}
            firstReadOnly
          />
          <FormField>
            <Label text="Subject"/>
            <FormControl>
              <TextInput
                name="subject"
                small
                expanded
                disabled={!sendMail}
                placeholder="Subject"
                value={subject}
                onChange={this.handleInputChange}
            />
            </FormControl>
          </FormField>
          <FormField>
            {!this.state.loading && !this.state.error && (
              <IntroductionTextInput
                name="message"
                disabled={!sendMail}
                html={message}
                placeholder="message"
                onChange={this.handleMessageChange}
              />
            )}
          </FormField>
        </Dynamic>
        <Divider />
      </Form>
    )

  }
}

export default PublishForm;
